import React, { Fragment, useState } from "react";
import { AssignedTileIcon, ExpiredTileIcon, ExpiringTileIcon, NotSubmittedTileIcon } from "../../../assets";
import { statusColours, handleSaveDocumentationFilters } from "../../../helpers";

export default function TilesSection(props) {
  const { selectedStatus, handleSelectedStatus, statusFilterCounts, filterDataLoaded, user, handleClearFilter, selectedInstructors, selectedCategories, selectedLocations, selectedSkills } = props
  const tilesList = [
    {
      name: 'Assigned',
      value: '',
      icon: <AssignedTileIcon />,
      count: statusFilterCounts['assigned'] || '',
      bgColor: 'bg-blue-gray-100'
    },
    {
      name: 'Expired',
      value: 'expired',
      icon: <ExpiredTileIcon strokeColor={'#991B1B'}/>,
      count: statusFilterCounts['expired'] || '',
      bgColor: statusColours['expired'].split(' ')[0]
    },
    {
      name: 'Expiring',
      value: 'expiring',
      icon: <ExpiringTileIcon strokeColor={'#9A3412'}/>,
      count: statusFilterCounts['expiring'] || '',
      bgColor: statusColours['expiring'].split(' ')[0]
    },
    {
      name: 'Not Submitted',
      value: 'not_submitted',
      icon: <NotSubmittedTileIcon strokeColor={'#6B7280'}/>,
      count: statusFilterCounts['not_submitted'] || '',
      bgColor: statusColours['not_submitted'].split(' ')[0]
    },
    {
      name: 'Submitted',
      value: 'submitted',
      icon: <AssignedTileIcon strokeColor={'#065F46'}/>,
      count: statusFilterCounts['submitted'] || '',
      bgColor: statusColours['submitted'].split(' ')[0]
    }
  ]

  const handleTileOnClick = (status) => {
    if(status == '') {
      handleClearFilter()
      return;
    }
    const __FOUND = selectedStatus.includes(status)
    const newStatus = __FOUND
      ? selectedStatus.filter((tag) => tag !== status)
      : status ? [status] : []
    const state = newStatus.length === 4
      ? 'all_selected'
      : newStatus.length === 0
        ? 'no_selected'
        : 'some_selected'
    handleSelectedStatus(newStatus)
    handleSaveDocumentationFilters(newStatus, state, 'status', user?.id)
  }

  const getCardClassForTiles = (selectedTiles, tile) => {
    return (
      (selectedTiles?.length == 0 && selectedInstructors?.length == 0 && selectedCategories?.length == 0 && selectedLocations?.length == 0 && selectedSkills?.length == 0 && tile?.value == '') || selectedTiles?.includes(tile?.value) ?
        `${tile?.bgColor} border-gray-900 hover:opacity-75` :
        'bg-white hover:bg-gray-100'
    )
  }

  return(
    <Fragment>
      <div className="flex flex-wrap items-center gap-6 mb-5">
        {tilesList.map((tile, index) => (
          <button
            key={index}
            className={`flex rounded-2xl p-4 gap-3 border border-transparent ${getCardClassForTiles(selectedStatus, tile)}`}
            disabled={!filterDataLoaded}
            onClick={() => handleTileOnClick(tile.value)}
          >
            <div className={`flex items-center justify-center wc-50 hc-50 rounded-20 ${tile?.bgColor}`}>
              {tile?.icon}
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex justify-start text-2xl font-bold h-8">
                {tile?.count}
              </div>
              <div className="tile-card-name-color font-semibold text-base">
                {tile?.name}
              </div>
            </div>
          </button>
        ))}
      </div>
    </Fragment>
  )
}