import React, { Fragment, useState } from "react";
import ExportButton from './exportButton';
import TilesSection from './tilesSection';
import SearchSection from './searchSection';
import InstructorDisabledFilter from './filtersSections/instructorDisabledFilter';
import FiltersPopup from './filtersSections/filtersPopup';
import StatusDisabledFilter from './filtersSections/statusDisabledFilter';
import CategoryDisabledFilter from './filtersSections/categoryDisabledFilter';
import LocationDisabledFilter from './filtersSections/locationDisabledFilter';
import FilterButton from './filtersSections/filterButton';
import ClearButton from './filtersSections/clearButton';
import SkillsDisabledFilter from './filtersSections/skillsDisabledFilter';

export default function FilterComponents(props) {
  const { selectedInstructors, handleSelectInstructors, user, selectedStatus, handleSelectedStatus, selectedCategories, handleSelectedCategories, categories, statusFilterCounts, regionLocationsList, showRegions, selectedLocations, selectedRegions, hanldleSelectLocations, totalCount, filterDataLoaded, searchValue, handleSearch, handleClearFilter, handleUpdateAllFilters, selectedSkills, businessSkills } = props
  const [ showFilterPopup, setShowFilterPopup ] = useState(false)
  
  return(
    <Fragment>
      {showFilterPopup &&
        <FiltersPopup
          show={showFilterPopup}
          close={() => setShowFilterPopup(false)}
          selectedInstructors={selectedInstructors}
          handleSelectInstructors={handleSelectInstructors}
          user={user}
          selectedStatus={selectedStatus}
          handleSelectedStatus={handleSelectedStatus}
          selectedCategories={selectedCategories}
          handleSelectedCategories={handleSelectedCategories}
          categories={categories}
          statusFilterCounts={statusFilterCounts}
          regionLocationsList={regionLocationsList}
          showRegions={showRegions}
          selectedLocations={selectedLocations}
          selectedRegions={selectedRegions}
          hanldleSelectLocations={hanldleSelectLocations}
          handleClearFilter={handleClearFilter}
          handleUpdateAllFilters={handleUpdateAllFilters}
          selectedSkills={selectedSkills}
          businessSkills={businessSkills}
        />
      }
      <TilesSection
        selectedStatus={selectedStatus}
        handleSelectedStatus={handleSelectedStatus}
        statusFilterCounts={statusFilterCounts}
        totalCount={totalCount}
        filterDataLoaded={filterDataLoaded}
        user={user}
        handleClearFilter={handleClearFilter}
        selectedInstructors={selectedInstructors}
        selectedCategories={selectedCategories}
        selectedLocations={selectedLocations}
        selectedSkills={selectedSkills}
      />
      <div className="flex flex-wrap flex-col sm:flex-row justify-between gap-2 sm:items-center mb-5 documentations-filter">
        <div className="flex flex-wrap flex-col sm:flex-row justify-start gap-2 sm:items-center">
          <SearchSection
            searchValue={searchValue}
            handleSearch={handleSearch}
          />
          {selectedInstructors?.length > 0 &&
            <InstructorDisabledFilter
              selectedInstructors={selectedInstructors}
              setShowFilterPopup={setShowFilterPopup}
            />
          }
          {selectedStatus?.length > 0 &&
            <StatusDisabledFilter
              selectedStatus={selectedStatus}
              setShowFilterPopup={setShowFilterPopup}
            />
          }
          {selectedCategories?.length > 0 &&
            <CategoryDisabledFilter
              selectedCategories={selectedCategories}
              categoriesList={categories}
              setShowFilterPopup={setShowFilterPopup}
            />
          }
          {selectedLocations?.length > 0 &&
            <LocationDisabledFilter
              regionLocationsList={regionLocationsList}
              selectedFilterLocations={selectedLocations}
              setShowFilterPopup={setShowFilterPopup}
            />
          }
          {selectedSkills?.length > 0 &&
            <SkillsDisabledFilter
              selectedSkills={selectedSkills}
              setShowFilterPopup={setShowFilterPopup}
            />
          }
          <FilterButton
            showFilterPopup={showFilterPopup}
            setShowFilterPopup={setShowFilterPopup}
          />
          {(selectedInstructors?.length > 0 || selectedStatus?.length > 0 || selectedCategories?.length > 0 || selectedLocations?.length > 0 || selectedSkills?.length > 0) &&
            <ClearButton
              handleClearFilter={handleClearFilter}
            />
          }
        </div>
        <ExportButton selectedInstructors={selectedInstructors} selectedStatus={selectedStatus} selectedCategories={selectedCategories} selectedLocations={selectedLocations} selectedSkills={selectedSkills}/>
      </div>
    </Fragment>
  )
}