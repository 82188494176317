import React, { Fragment, useState, useEffect, useCallback, useRef } from "react";
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { SearchIcon } from '../../../../../subRequests/assets';
import { chekLocationNameCount } from '../../../../../subRequests/helpers/helpers';
import { debounce } from "lodash";

export default function SkillsFilter(props) {
  const { selectedSkills, handleSelectSkills, businessSkills, page='documentations' } = props
  const menuButtonRef = useRef(null);
  const [ searchValue, setSearchValue ] = useState('')
  const allSkillsList = businessSkills || []
  const pluckSelectedClsIds = (selectedClses) => {
    return(selectedClses?.map((cls) => cls?.id))
  }
  const [ selectedSkillsIds, setSelectedSkillsIds ] = useState(pluckSelectedClsIds(selectedSkills || []))
  const [ skillsList, setSkillsList ] = useState(businessSkills || [])
  const [ selectedSklHash, setSelectedSklHash ] = useState(selectedSkills || [])
  let documentationPage = page === 'documentations'

  useEffect(() => {
    setSelectedSklHash(selectedSkills)
    setSelectedSkillsIds(pluckSelectedClsIds(selectedSkills || []))
  }, [selectedSkills])

  const handleClassesSearch = (e) => {
    let val = e.target.value
    if (e.key === ' ') {
      e.preventDefault();
      val += ' ';
    }
    setSearchValue(val)
    searchClasses(val)
  }

  const searchClasses = useCallback(
    debounce(val => {
      if(val.trim() == '') {
        setSkillsList(businessSkills || []);
      }else{
        handleSearchClasses(val.trim())
      }
    }, 800),
    [selectedSkillsIds, businessSkills]
  );

  const handleSearchClasses = (text) => {
    if(text == ''){
      setSkillsList(businessSkills || [])
    }else{
      setSkillsList(allSkillsList.filter((cls) => cls?.name?.toLowerCase().includes(text?.toLowerCase())));
    }
  }

  const processSelectClasses = (e, clss) => {
    let clsId = parseInt(clss?.id)
    var __FOUND = selectedSkillsIds.includes(clsId)
    if(e.target.checked){
      if(!__FOUND){
        let newClassIds = [...selectedSkillsIds, clsId]
        setSelectedSkillsIds(newClassIds)
        let newCls = {
          id: clsId,
          name: clss?.name
        }
        let newSelectedValue = [...selectedSklHash, newCls]
        setSelectedSklHash(newSelectedValue)
        if(documentationPage) handleSelectSkills(newSelectedValue);
      }
    }else{
      if(__FOUND){
        let newClassIds = selectedSkillsIds.filter((id) => id !== clsId)
        setSelectedSkillsIds(newClassIds)
        let newSelectedValue = selectedSklHash.filter((cls) => cls?.id !== clsId)
        setSelectedSklHash(newSelectedValue)
        if(documentationPage) handleSelectSkills(newSelectedValue);
      }
    }
  }

  const classesNames = () => {
    let names = []
    let classes = selectedSklHash
    let ctr = 0
    for(let i = 0; i < classes?.length; i++){
      if(ctr < 2){
        names.push(classes[i].name)
        ctr++
      }
      else{
        break;
      }
    }
    return chekLocationNameCount(names.join(", "))
  }

  const processFillBackFilter = () => {
    if(!documentationPage){
      setSelectedSklHash(selectedSkills)
      setSelectedSkillsIds(pluckSelectedClsIds(selectedSkills || []))
    }
    setSkillsList(businessSkills || []);
    setSearchValue('');
  }

  useEffect(() => {
    if(selectedSkillsIds?.length > 0){
      $('.skillsspecial_drop-down_button').addClass('filter-apply-border')
    }else {
      $('.skillsspecial_drop-down_button').removeClass('filter-apply-border')
    }
  }, [selectedSkillsIds])

  const setClassesDropDownRef = (node) => {
    if(node){
      $('.skillsspecial_drop-down_button').addClass('filter-apply-border')
    }else if (node === null) {
      if(selectedSkillsIds?.length == 0) $('.skillsspecial_drop-down_button').removeClass('filter-apply-border')
      processFillBackFilter()
    }
  }

  const cancelSkillsFilter = () => {
    menuButtonRef.current?.click()
  }

  const checkSelectAll = () => {
    let businessSkillIds = skillsList.map((a) => a.id);
    return (businessSkillIds.every(v => selectedSkillsIds.includes(v)) && skillsList?.length > 0)
  }

  const selectAllSkills = () => {
    let skillIds = skillsList.map(a => a.id);
    if(checkSelectAll()){
      let newClassIds = selectedSkillsIds.filter((id) => !skillIds.includes(id))
      setSelectedSkillsIds(newClassIds)
      let newSelectedValue = selectedSklHash.filter((cls) => !skillIds.includes(cls?.id))
      setSelectedSklHash(newSelectedValue)
      if(documentationPage) handleSelectSkills(newSelectedValue);
    }else{
      let newClassIds = [...new Set([...selectedSkillsIds, ...skillIds])]
      setSelectedSkillsIds(newClassIds)
      let allSelectedValue = [...selectedSklHash, ...skillsList]
      let newSelectedValue = allSelectedValue.filter((cls) => newClassIds.includes(cls?.id))
      const uniqueById = Array.from(
        new Map(newSelectedValue.map(item => [item.id, item])).values()
      );
      setSelectedSklHash(uniqueById)
      if(documentationPage) handleSelectSkills(uniqueById);
    }
  }

  const handleApplyFilter = () => {
    handleSelectSkills(selectedSklHash)
    menuButtonRef.current.click()
  }

  return(
    <Fragment>
      <div className="relative">
        <Menu as="div" className="relative block text-left">
          <div>
            <Menu.Button className={`flex justify-center w-full pl-3 pr-2 text-sm text-gray-900 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 skillsspecial_drop-down_button border-2 need-border font-medium bg-white ${documentationPage ? 'rounded-lg py-2.5 border-gray-200' : 'rounded-md py-2 border-gray-300'}`} ref={menuButtonRef}>
              {allSkillsList?.length == selectedSklHash?.length ? 
                (allSkillsList?.length > 0 && <p className = "text-gray-900">All Skills</p>)
                :
                (selectedSklHash?.length > 0 ?
                  <React.Fragment>
                    {(selectedSklHash?.length === 1) && 
                      <p className = "text-gray-900">{classesNames()}</p>
                    }
                    {(selectedSklHash?.length > 1) && 
                      <div className = "flex text-gray-900 items-center">
                        <div className={`text-gray-900 truncate max-wc-160`}>{classesNames()}</div>
                        {(selectedSklHash?.length - classesNames()?.split(',')?.length) > 0 && <p className="text-xs ml-1">+{selectedSklHash?.length - classesNames()?.split(',')?.length}</p>}
                      </div>
                    }
                  </React.Fragment>
                  :
                  <p className = "text-gray-900">Select skill(s)</p>
                )
              }
              <ChevronDownIcon className="ml-auto text-gray-900 h-5 w-5" aria-hidden="true" />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            // show={true}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items ref={setClassesDropDownRef} className={`origin-top-right z-30 right-0 w-full bg-white ring-2 ring-gray-500 focus:outline-none mt-2 absolute ${documentationPage ? 'rounded-lg' : 'rounded-md'}`}>
              <div className="py-2 h-60 max-h-60 overflow-y-auto">
                <div className='bg-white rounded-2xl'>
                  <div className='bg-gray-custom-50 rounded-2xl py-1.5 px-3.5 mx-5 my-1'>
                    <div className='flex items-center'>
                      <SearchIcon classNames={'h-5 w-5 text-gray-400'}/>
                      <input type="text" className="ml-1.5 focus:outline-none w-full bg-transparent" placeholder="Search" value={searchValue} onChange={(e) => handleClassesSearch(e)} onKeyDown={handleClassesSearch}></input>
                    </div>
                  </div>
                </div>
                <div className='flex items-center justify-between px-5 py-2 mb-1'>
                  <label className="inline-flex items-center text-sm">
                    <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-2" checked = {checkSelectAll()} onChange = {(e) => selectAllSkills(e)}/>
                    Select all
                  </label>
                  <a onClick={() => cancelSkillsFilter()} className='custom-red text-sm cursor-pointer'>Cancel</a>
                </div>
                <div className=''>  
                  {skillsList.map((cls, i) => 
                    <div className="" key = {i}>
                      <label className="flex px-5 text-gray-900 text-sm mb-2">
                        <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-2 mt-0.5 cursor-pointer" onChange={(e) => processSelectClasses(e, cls)} value={cls?.id} checked={selectedSkillsIds.includes(cls?.id)}/>
                        <div className='flex items-center gap-2 cursor-pointer'>{cls?.name}</div>
                      </label>
                    </div>
                  )}
                </div>
              </div>
              {!documentationPage &&
                <div className="flex items-center p-2 border-t">
                  <div className="flex justify-center items-center w-full px-3 gap-3">
                    <div className=''>
                      <a className='bg-dark-blue text-white text-sm rounded-md py-2 px-4 font-medium h-9 block cursor-pointer' onClick={(e) => handleApplyFilter()}>Apply</a>
                    </div>
                  </div>
                </div>
              }
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </Fragment>
  )
}