import React, { Component, Fragment } from 'react';
import { getSudomain, setTitle, timeZones, checkSettingsPermissios } from '../../../../helpers'
import { SpinnerWithMessage, AlertPopup } from '../assets'
import { axiosGetRequest, initial_user, validateEmail,usersForActivationEmails } from './../helpers'
import { setLocationSettings, createUser, updateUser, syncMarianatekEmployees, processSyncCrFisikalUsers, findMatchingRoleAndSlice, checkFilterSaved, handleSaveFilters } from './../../helpers'
import Cookies from 'js-cookie';
import UserTable from '../components/userTable/userTable';
import Pagination from "react-js-pagination";
import SearchComponent from '../components/filtersAndButtons/searchComponent/searchComponent';
import { debounce } from "lodash";
import LocationComponent from '../components/filtersAndButtons/locationComponent/locationComponent';
import StatusComponent from './../components/filtersAndButtons/statusComponent/statusComponent';
import RoleComponent from './../components/filtersAndButtons/roleComponent/roleComponent'
import ExportComponent from '../components/filtersAndButtons/exportComponent/exportComponent';
import UserForm from './../../addUserForm'
import TabComponent from '../components/filtersAndButtons/tabComponent/tagComponent';
import SendActivationEmailComponent from '../components/filtersAndButtons/sendActivationEmailComponent/sendActivationEmailComponent'
import UploadCsvComponent from '../components/filtersAndButtons/uploadCsvComponent/uploadCsvComponent';
import ResetSelectionComponent from '../components/filtersAndButtons/resetSelectionComponent/resetSelectionComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faSpinner } from '@fortawesome/free-solid-svg-icons';
import HomeClubComponent from '../components/filtersAndButtons/homeClubComponent';
import { syncStaff } from '../../../../configure/details/helpers'
import AssignDocumentationPopup from '../components/massActionComponent/assignDocumentationPopup';
import AssignSkillsPopup from '../components/massActionComponent/assignSkillsPopup';
import AssignLocationsPopup from '../components/massActionComponent/assignLocationsPopup';
import SkillsFilter from '../../../../documentations/components/instructorDocumentations/filterComponents/filtersSections/skillsFilter';

export default class IntegratedBusinessUserPage extends Component{
  constructor(props){
    super(props);
    this.state = {
      loaded: false,
      pageTab: 'active',
      check: false,
      locationSettings: [],
      showFilterOther: null,
      selectedLocations: [],
      selectedRegions: [],
      alert_message: "",
      alert_type: 'success',
      show: false,
      users: [],
      total: 0,
      activePage: 1,
      noSkillUsers: 0,
      inactiveUsers: 0,
      deleteModal: false,
      showModal: false,
      emailModal: false,
      sendAllModal: false,
      syncModal: false,
      passwordModal: false,
      activateModal: false,
      deactivateModal: false,
      dataLoaded: false,
      selectedStatus: '',
      selectedRole: '',
      rolesName: [],
      sortType: 'signup',
			sortReverse: false,
      pageSize: 30,
      search_user: '',
      search_text: '',
      userRoles: [],
      blank: true,
      showFormOther: null,
      teachingRegionWithLocations: [],
      showFormOtherTeaching: null,
      regionWithLocations: [],
      skills: [],
      userForm: initial_user,
      time_zones: timeZones(),
      showModal: false,
      formType: '',
      selectAllPeople: false,
      usersIds: [],
      userSyncSkills: [],
      dataReady: false,
      selectedHomeClubRegions: [],
      selectedHomeClubLocations: [],
      documentationCategories: [],
      userIdsForMassActions: [],
      selectedPeopleForMassActions: [],
      showAssignCategoryPopup: false,
      showAssignSkillPopup: false,
      showAssignLocationsPopup: false,
      selectedSkills: [],
      businessSkills: [],
    }
  }

  componentDidMount() {
    if(Object.keys(this.props?.user).length !== 0 && Object.keys(this.props?.permissions).length !== 0){
      checkSettingsPermissios('users', this.props)
      this.checkUserRoles(this.props?.user?.role_name)
    }
    if(this.props.pageLoaded){
      this.getLocations()
      if(this.props?.business?.documentations_feature && (this.props?.user?.role_display == "Netgym Employee" || this.props?.permissions?.documentations?.write || this.props?.permissions?.documentations?.read)) this.getDocumentationCategories()
      this.setState({check: true})
    }
		setTitle('users')
  }

  componentWillUnmount() {
    this.setState = (state,callback)=>{
      return;
    };
  }

  checkUserRoles = (roleName) => {
    let allRoles = findMatchingRoleAndSlice(roleName)
    let rolesName = allRoles.map(item => item.value)
    this.setState({
      rolesName: rolesName,
      userRoles: allRoles
    })
  }

  checkLastFilters = () => {
    let subdomain = this.props?.user?.subdomain
    if(checkFilterSaved(this.props?.user?.id, subdomain)){
      let lastFilters = JSON.parse(localStorage.getItem(`usersFilters_${subdomain}`));
      if(lastFilters){
        this.setState({
          sortType: lastFilters?.sort?.type || 'signup',
          sortReverse: lastFilters?.sort?.order || false,
          search_user: lastFilters?.search || '',
          selectedHomeClubLocations: lastFilters?.homeClubLocations || [],
          selectedHomeClubRegions: lastFilters?.homeClubRegions || [],
          selectedRole: lastFilters?.role || '',
          selectedStatus: lastFilters?.status || '',
          selectedSkills: lastFilters?.skills || []
        })
      }
    }else{
      localStorage.removeItem(`usersFilters_${subdomain}`);
    }
  }

  getLocations = async () => {
    await this.checkLastFilters()
    axiosGetRequest(`/api/v3/get_user_location_and_skills`)
    .then(async result => {
      if(result.status === 200){
        let regions_and_locations = result.regions_and_locations.sort((a, b) => (a.region?.name > b.region?.name) ? 1 : -1)
        let filter_regions_and_locations = result.filter_regions_and_locations.sort((a, b) => (a.region?.name > b.region?.name) ? 1 : -1)
        this.setState({
          showFormOther: result.show_other,
          regionWithLocations: regions_and_locations,
          showFormOtherTeaching: result.show_other,
          teachingRegionWithLocations: regions_and_locations,
          skills: result.business_categories,
          userSyncSkills: result?.integration_skills,
          locationSettings: filter_regions_and_locations,
          showFilterOther: result.filter_show_other,
          businessSkills: result.business_categories.map(item => {return {id: item.id, name: item.name}})
        })
        this.setSelectedLocations(result.filter_regions_and_locations)
        if(this.props.user.role_display === "Netgym Employee" && this.state.check === true){
          this.setAllLocationForNE(result.filter_regions_and_locations)
        }
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true,
          loaded: true
        })
      }
    })
  }

  setSelectedLocations = (regions) => {
    let selected_regions = []
    let selected_locations = []
    for(var i=0; i<regions.length;i++){
      if(regions[i]['region']['checked'] === true){
        selected_regions.push(regions[i]['region']['id'])
      }
      for(var j=0; j<regions[i]['locations'].length; j++){
        if(regions[i]['locations'][j]['checked'] === true){
          selected_locations.push(regions[i]['locations'][j]['id'])
        }
      }
    }
    this.setState({
      selectedLocations: selected_locations,
      selectedRegions: selected_regions
    })
    this.getAll(1)
  }

  setAllLocationForNE(locationSettings) {
    this.setState({check: false})
    let all = locationSettings
    let selected_regions = []
    let selected_locations = []
    for(var i=0; i<all.length;i++){
      selected_regions.push(all[i]['region']['id'])
      for(var j=0; j<all[i]['locations'].length; j++){
        selected_locations.push(all[i]['locations'][j]['id'])
      }
    }
    this.setState({
      selectedLocations: selected_locations,
      selectedRegions: selected_regions,
    })
    // this.setSelLocations()
  }

  getUsers = (page, selectedLocations, selectedStatus, selectedRole, pageTab, sortType, sortReverse, search, selectedSkills) => {
    let selectedSkillIds = selectedSkills.map(function(a) {return a?.id});
    axiosGetRequest(`/api/v3/users?page=${page}&pageSize=30&ids=${selectedLocations}&selectedStatus=${selectedStatus}&selectedRole=${selectedRole}&pageTab=${pageTab}&sortType=${sortType}&sortReverse=${sortReverse}&text=${search}&skill_ids=${selectedSkillIds}`)
    .then(result => {
			if(result.status === 200){
        this.setState({
          users: result.results,
          usersIds: usersForActivationEmails(result.results),
          total: result.total,
          activePage: parseInt(result.page),
          inactiveUsers: result.inactive_users_count,
          deleteModal: false,
          showModal: false,
          emailModal: false,
          sendAllModal: false,
          syncModal: false,
          passwordModal: false,
          activateModal: false,
          deactivateModal: false,
          userIdsForMassActions: result.results?.map(item => item.id),
          selectedPeopleForMassActions: [],
        }, function () {
            this.setState({
              loaded: true,
              dataLoaded: true
            })
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true,
          loaded: true
        })
      }
    })
  }

  getAll = (pageNumber) => {
		this.getUsers(pageNumber, this.state.selectedLocations, this.state.selectedStatus, this.state.selectedRole, this.state.pageTab, this.state.sortType, this.state.sortReverse, this.state.search_user, this.state.selectedSkills)

    axiosGetRequest(`/api/v3/get_roles`)
    .then(result => {
      if(result.status === 200){
        let roles = result.roles
        let rolesName = []
        for (let i = 0; i < roles?.length; i++){
          rolesName.push(roles[i].value)
        }
        this.setState({
          rolesName: rolesName,
          userRoles: result.roles
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true,
          loaded: true
        })
      }
    })
	}

  closeAlert = (data) => {
    this.setState({
      show: data,
      alert_type: '',
      alert_message: ''
    })
  }

  setSelLocations = () => {
    let locations = this.state.selectedLocations;
    if(locations.length === 0){
      this.setState({
        alert_message: 'Please select atleast one location',
        alert_type: 'danger'
      })
      this.setState({show:true},()=>{
        window.setTimeout(()=>{
          this.setState({show:false})
        },3000)
      });
      return;
    }
    // if(locations.length > 0 && !this.selectedAllRegLoc()){
    //   this.setState({search_text: this.location_filter_message()})
    // }
    this.setState({
      dataLoaded: false
    })
    // window.hideFilter()

    setLocationSettings(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), this.state.selectedLocations, this.state.selectedRegions).then(
      response => response.json()
    )
    .then(result => {
			if(result.status === 200){
        this.getLocations()
        this.setState({
          // loaded: true
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true,
          loaded: true
        })
      }
    })
  }

  setTheState = (paramName, paramData) => {
    this.setState({
      [paramName]: paramData
    })
  }

  handlePageChange = (pageNumber) => {
    this.setState({activePage: pageNumber, dataLoaded: false, selectedPeopleForMassActions: []});
    this.paginatedResults(pageNumber)
  }

  paginatedResults = (pageNumber) => {
    this.getFilterUsers(pageNumber, this.state.search_user, this.state.sortType, this.state.sortReverse, this.state.selectedLocations, this.state.selectedStatus, this.state.selectedRole, this.state.pageTab, this.state.selectedHomeClubLocations, this.state.selectedSkills)
  }

  search = (search) => {
    this.setState({
      search_user: search,
      search_text: search
    })
    this.debounceTest(search)
  }

  debounceTest = debounce(val => {
    this.performSearch(val)
  }, 800);

  performSearch = (search) => {
    this.updateFiltersInStorage(search, 'search')
    this.getFilterUsers(1, search, this.state.sortType, this.state.sortReverse, this.state.selectedLocations, this.state.selectedStatus, this.state.selectedRole, this.state.pageTab, this.state.selectedHomeClubLocations, this.state.selectedSkills)
  }

  getFilterUsers = (page, search, sortType, sortReverse, selectedLocations, selectedStatus, selectedRole, pageTab, selectedHomeClubLocations, selectedSkills) => {
    this.setState({dataLoaded: false})
    this.processGetFilterUsers(page, search, sortType, sortReverse, selectedLocations, selectedStatus, selectedRole, pageTab, selectedHomeClubLocations, selectedSkills)
  }

  processGetFilterUsers = (page, search, sortType, sortReverse, selectedLocations, selectedStatus, selectedRole, pageTab, selectedHomeClubLocations, selectedSkills) => {
    let selectedSkillIds = selectedSkills.map(function(a) {return a?.id});
    axiosGetRequest(`/api/v3/users?page=${page}&pageSize=30&ids=${selectedLocations}&selectedStatus=${selectedStatus}&selectedRole=${selectedRole}&pageTab=${pageTab}&sortType=${sortType}&sortReverse=${sortReverse}&text=${search}&selectedHomeClubs=${selectedHomeClubLocations}&skill_ids=${selectedSkillIds}`).then(result => {
      if(result.status === 200){
        let uIds = [...new Set([...this.state.usersIds, ...usersForActivationEmails(result.results)])]
        this.setState({
          users: result.results,
          usersIds: uIds,
          activePage: parseInt(result.page),
          dataLoaded: true,
          total: result.total,
          userIdsForMassActions: result.results?.map(item => item.id),
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
      }
    })
  }

  handleSelectStatus = (data) => {
    this.setState({selectedStatus: data})
    this.updateFiltersInStorage(data, 'status')
    this.getFilterUsers(1, this.state.search_user, this.state.sortType, this.state.sortReverse, this.state.selectedLocations, data, this.state.selectedRole, this.state.pageTab, this.state.selectedHomeClubLocations, this.state.selectedSkills)
  }

  handleSelectedRole = (data) => {
    this.setState({selectedRole: data})
    this.updateFiltersInStorage(data, 'role')
    this.getFilterUsers(1, this.state.search_user, this.state.sortType, this.state.sortReverse, this.state.selectedLocations, this.state.selectedStatus, data, this.state.pageTab, this.state.selectedHomeClubLocations, this.state.selectedSkills)
  }

  setAdd = (e) => {
    e.preventDefault()
    this.setState({
      blank: false,
      dataReady: false,
      showModal: true,
      formType: 'add',
      userForm: initial_user
    },()=>{
      window.setTimeout(()=>{
        this.setState({dataReady: true})
      },50)
    })
  }

  setEdit = (e, user) => {
    e.preventDefault()
    this.setState({
      dataReady: false,
      showModal: true,
      formType: 'edit',
      userForm: user
    },()=>{
      window.setTimeout(()=>{
        this.setState({dataReady: true})
      },50)
    })
  }

  closeModal = () => {
    this.setState({
      showModal: false,
    })
  }

  addUser = (req) => {
    this.setState({showModal: false,})
    createUser(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), req).then(
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          alert_message: result.error,
          alert_type: 'success',
          // loaded: false,
        })
        this.getFilterUsers(1, this.state.search_user, this.state.sortType, this.state.sortReverse, this.state.selectedLocations, this.state.selectedStatus, this.state.selectedRole, this.state.pageTab, this.state.selectedHomeClubLocations, this.state.selectedSkills)
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger'
        })
      }
      this.setState({show:true},()=>{
        window.setTimeout(()=>{
          this.setState({show:false})
        },3000)
      });
    })
  }

  editUser = (req) => {
    this.setState({showModal: false,})
    updateUser(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), req).then(
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          alert_message: result.error,
          alert_type: 'success',
          dataLoaded: false,
          showModal: false,
        })
        if(req?.id === this.props.user?.id){
          this.props.setLoaded()
        }
        this.getFilterUsers(this.state.activePage, this.state.search_user, this.state.sortType, this.state.sortReverse, this.state.selectedLocations, this.state.selectedStatus, this.state.selectedRole, this.state.pageTab, this.state.selectedHomeClubLocations, this.state.selectedSkills)
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger'
        })
      }
      this.setState({show:true},()=>{
        window.setTimeout(()=>{
          this.setState({show:false})
        },3000)
      });
    })
  }

  handleSyncEmployees = (evt) => {
    evt.preventDefault();
    if (this.state.syncingEmployees) {
      return;
    }

    this.setState({ syncingEmployees: true });

    syncMarianatekEmployees(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()).then(
      response => response.json()
    ).then(result => {
      this.setState({
        syncingEmployees: result.status === 200,
        show: true,
        alert_message: result.status === 200 ? result.success : result.error,
        alert_type: result.status === 200 ? 'success' : 'danger'
      }, () => {
        window.setTimeout(() => {
          this.setState({ show: false })
        }, 5000);
        window.setTimeout(() => {
          this.setState({ syncingEmployees: false });
        }, result.lockExp * 1000);
      });
    });
  }

  sort = (e, param, type) => {
    e.preventDefault()
    let sort_desc = false
    if(type === 'asc'){
      sort_desc = false
    }else{
      let sortRev =  this.state.sortReverse
      if(sortRev === true){
        sort_desc = false
      }
      else{
        sort_desc = true
      }
    }

    this.setState({
      sortType: param,
      sortReverse: sort_desc
    }, () => {
      let data = {
        type: param,
        order: sort_desc
      }
      this.updateFiltersInStorage(data, 'sort')
      this.getFilterUsers(this.state.activePage, this.state.search_user, param, sort_desc, this.state.selectedLocations, this.state.selectedStatus, this.state.selectedRole, this.state.pageTab, this.state.selectedHomeClubLocations, this.state.selectedSkills)
    });
  }

  setPageTab = (data) => {
    this.setState({
      pageTab: data,
      selectedStatus: '',
      activePage: 1,
      sortType: 'signup',
			sortReverse: false,
      selectedPeopleForMassActions: [],
    })
    this.getFilterUsers(1, this.state.search_user, 'signup', false, this.state.selectedLocations, '', this.state.selectedRole, data, this.state.selectedHomeClubLocations, this.state.selectedSkills)
  }

  showAlert = (show, type, message) => {
    this.setState({
      show: show,
      alert_type: type,
      alert_message: message
    })
  }

  reloadDataAfterSendActivationEmail = () => {
    this.processGetFilterUsers(this.state.activePage, this.state.search_user, this.state.sortType, this.state.sortReverse, this.state.selectedLocations, this.state.selectedStatus, this.state.selectedRole, this.state.pageTab, this.state.selectedHomeClubLocations, this.state.selectedSkills)
  }

  handleSyncUsers = (e) => {
    e.preventDefault();
    if (this.state.syncingEmployees) {
      return;
    }
    this.setState({ syncingEmployees: true });
    processSyncCrFisikalUsers(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()).then(
      response => response.json()
    ).then(result => {
      this.setState({
        syncingEmployees: result.status === 200,
        show: true,
        alert_message: result.status === 200 ? result.message : result.error,
        alert_type: result.status === 200 ? 'success' : 'danger',
        syncingEmployees: false
      }, () => {
        window.setTimeout(() => {
          this.setState({ show: false })
        }, 5000);
      });
    })
  }

  syncMboUsers(e) {
    e.preventDefault();
    if (this.state.syncingEmployees) {
      return;
    }
    this.setState({ syncingEmployees: true });
    syncStaff(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()).then(
      response => response.json()
    ).then(result => {
      this.setState({
        show: true,
        alert_message: result.status === 200 ? result.success : result.error,
        alert_type: result.status === 200 ? 'success' : 'danger',
        syncingEmployees: false
      }, () => {
        window.setTimeout(() => {
          this.setState({ show: false })
        }, 5000);
      });
    })
  }

  handleSaveHomeClub = (locations, regions) => {
    let data = {
      locations: locations,
      regions: regions
    }
    this.updateFiltersInStorage(data, 'homeclub')
    this.getFilterUsers(1, this.state.search_user, this.state.sortType, this.state.sortReverse, this.state.selectedLocations, this.state.selectedStatus, this.state.selectedRole, this.state.pageTab, locations, this.state.selectedSkills)
  }

  closeAndReload = () => {
    this.setState({dataLoaded: false, showModal: false});

    this.getUsers(this.state.activePage, this.state.selectedLocations, this.state.selectedStatus, this.state.selectedRole, this.state.pageTab, this.state.sortType, this.state.sortReverse, this.state.search_user, this.state.selectedSkills)
  }

  getDocumentationCategories = () => {
    axiosGetRequest('/api/v3/documentation_categories').then(result => {
      if(result.status === 200){
        this.setState({
          documentationCategories: result.documentation_categories
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
        })
        this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
      }
    })
  }

  handleAssignDocumentation = (data) => {
    this.setState({
      showAssignCategoryPopup: data
    })
  }

  closeAssignCategoryPopup = () => {
    this.setState({
      showAssignCategoryPopup: false
    })
  }

  completeMassAssignActions = () => {
    this.setState({
      showAssignCategoryPopup: false,
      showAssignSkillPopup: false,
      showAssignLocationsPopup: false,
      selectedPeopleForMassActions: [],
      dataLoaded: false
    })
    this.reloadDataAfterSendActivationEmail()
  }

  handleAssignSkills = (data) => {
    this.setState({
      showAssignSkillPopup: data
    })
  }

  closeAssignSkillsPopup = () => {
    this.setState({
      showAssignSkillPopup: false
    })
  }

  handleAssignLocations = (data) => {
    this.setState({
      showAssignLocationsPopup: data
    })
  }

  closeAssignLocationsPopup = () => {
    this.setState({
      showAssignLocationsPopup: false
    })
  }

  updateFiltersInStorage = (data, type) => {
    handleSaveFilters(data, type, this.props?.user?.id, this.props?.user?.subdomain)
  }

  handleSelectSkills = (data) => {
    this.setState({
      selectedSkills: data
    })
    
    this.updateFiltersInStorage(data, 'skills')
    this.getFilterUsers(1, this.state.search_user, this.state.sortType, this.state.sortReverse, this.state.selectedLocations, this.state.selectedStatus, this.state.selectedRole, this.state.pageTab, this.state.selectedHomeClubLocations, data)
  }

  render(){
    const { loaded, show, pageTab, alert_message, alert_type, users, total, sortType, sortReverse, dataLoaded, pageSize, activePage, rolesName, locationSettings, showFilterOther, selectedLocations, selectedRegions, selectedStatus, selectedRole, userRoles, showModal, formType, userForm, regionWithLocations, teachingRegionWithLocations, time_zones, skills, showFormOther, showFormOtherTeaching,  usersIds, selectAllPeople, userSyncSkills, dataReady, selectedHomeClubRegions, selectedHomeClubLocations, documentationCategories, userIdsForMassActions, selectedPeopleForMassActions, showAssignCategoryPopup, showAssignSkillPopup, showAssignLocationsPopup, search_user, selectedSkills, businessSkills } = this.state
    const {user, business, loadingMessage, permissions} = this.props

    return(
      <Fragment>
        {show && <AlertPopup alert_message={alert_message} alert_type={alert_type} closeAlert={this.closeAlert} />}
        {showAssignCategoryPopup && <AssignDocumentationPopup showAssignCategoryPopup={showAssignCategoryPopup} closeAssignCategoryPopup={this.closeAssignCategoryPopup} documentationCategories={documentationCategories} selectedPeopleForMassActions={selectedPeopleForMassActions} showAlert={this.showAlert} completeMassAssignActions={this.completeMassAssignActions}/>}
        {showAssignSkillPopup && <AssignSkillsPopup showAssignSkillPopup={showAssignSkillPopup} closeAssignSkillsPopup={this.closeAssignSkillsPopup} skills={skills} selectedPeopleForMassActions={selectedPeopleForMassActions} showAlert={this.showAlert} completeMassAssignActions={this.completeMassAssignActions} business={business} />}
        {showAssignLocationsPopup && <AssignLocationsPopup showAssignLocationsPopup={showAssignLocationsPopup} closeAssignLocationsPopup={this.closeAssignLocationsPopup} regionWithLocations={regionWithLocations} selectedPeopleForMassActions={selectedPeopleForMassActions} showAlert={this.showAlert} completeMassAssignActions={this.completeMassAssignActions} showFilterOther={showFilterOther} />}
        <div className='w-full min-h-screen bg-gray-50'>
          {!loaded &&
            <SpinnerWithMessage loadingMessage={loadingMessage} />
          }
          {loaded &&
            <div className='max-w-8xl mx-auto h-full'>
              <div className='flex flex-wrap gap-2 mb-5'>
                <TabComponent pageTab={pageTab} setPageTab={this.setPageTab}/>
              </div>
              <div className='flex flex-wrap justify-between'>
                <div className='flex flex-wrap gap-2 mb-3'>
                  <SearchComponent searchHandler={this.search} searchValue={search_user}/>
                  {(pageTab == 'inactive') &&
                    <StatusComponent selectedStatus={selectedStatus} handleSelectStatus={this.handleSelectStatus} />
                  }
                  <LocationComponent locationSettings={locationSettings} showFilterOther={showFilterOther} selectedLocations={selectedLocations} selectedRegions={selectedRegions} setTheState={this.setTheState} setSelLocationsHandler={this.setSelLocations}/>
                  <RoleComponent selectedRole={selectedRole} handleSelectedRole={this.handleSelectedRole} userRoles={userRoles} />
                  {user?.role_display !== "Netgym Employee" &&
                    <ExportComponent pageTab={pageTab}/>
                  }
                  {(business?.marianatek_integration || business?.clubready_integration || business?.fisikal_integration || business?.abc_integration || business?.crunch_integration || (business?.mbo_integration && business?.multi_site_multi_staff_feature)) && (permissions?.business_users?.write || user?.role_display == "Netgym Employee") && (
                    <a className="border rounded-md border-gray-300 shadow-sm text-gray-700 text-sm py-2 px-4 h-10 block cursor-pointer flex items-center bg-white hover:bg-gray-100" onClick={(e) => {business?.marianatek_integration ? this.handleSyncEmployees(e) : business?.mbo_integration ? this.syncMboUsers(e) : this.handleSyncUsers(e)}}>
                       <FontAwesomeIcon size="lg" icon={this.state.syncingEmployees ? faSpinner : faUsers} spin={this.state.syncingEmployees} />
                       &nbsp;Synchronize Users
                    </a>
                  )}
                  <HomeClubComponent locationSettings={locationSettings} showFilterOther={showFilterOther} selectedHomeClubLocations={selectedHomeClubLocations} selectedHomeClubRegions={selectedHomeClubRegions} setTheState={this.setTheState} handleSaveHomeClub={this.handleSaveHomeClub}/>
                  <div className="w-56">
                    <SkillsFilter selectedSkills={selectedSkills} handleSelectSkills={this.handleSelectSkills} businessSkills={businessSkills} page={'settings'}/>
                  </div>
                </div>
                {user?.role_display == "Netgym Employee" &&
                  <div className='flex flex-wrap gap-2'>
                      {((pageTab == 'inactive') && !business?.fisikal_integration && !business.clubready_integration && !business?.abc_integration && !business?.crunch_integration && !(business?.mbo_integration && business?.multi_site_multi_staff_feature)) &&
                        <UploadCsvComponent business={business} showAlert={this.showAlert} setTheState={this.setTheState} reloadDataAfterSendActivationEmail={this.reloadDataAfterSendActivationEmail}/>
                      }
                    <ExportComponent pageTab={pageTab}/>
                    {(!business?.fisikal_integration && !business.clubready_integration && !business?.abc_integration && !business?.crunch_integration && !(business?.mbo_integration && business?.multi_site_multi_staff_feature)) &&
                      <div className=''>
                        <a className='bg-dark-blue text-white hover:bg-gray-800 text-sm rounded-md py-2 px-4 font-medium h-10 block cursor-pointer flex items-center' onClick={(e) => this.setAdd(e)}>Add New User</a>
                      </div>
                    }
                  </div>
                }
              </div>
              <div className='mt-2 overflow-x-auto overflow-y-hidden sm:-mx-6 lg:-mx-8 mb-4'>
                <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
                  {!dataLoaded &&
                    <SpinnerWithMessage loadingMessage={loadingMessage} />
                  }
                  {dataLoaded &&
                    <UserTable
                      business={business}
                      user={user}
                      permissions={permissions}
                      users={users}
                      total={total}
                      sortType={sortType}
                      sortReverse={sortReverse}
                      setTheState={this.setTheState}
                      rolesName={rolesName}
                      setEditHandler={this.setEdit}
                      sortHandler={this.sort}
                      pageTab={pageTab}
                      usersIds={usersIds}
                      reloadDataAfterSendActivationEmail={this.reloadDataAfterSendActivationEmail}
                      selectAllPeople={selectAllPeople}
                      userIdsForMassActions={userIdsForMassActions}
                      selectedPeopleForMassActions={selectedPeopleForMassActions}
                      handleAssignDocumentation={this.handleAssignDocumentation}
                      handleAssignSkills={this.handleAssignSkills}
                      handleAssignLocations={this.handleAssignLocations}
                    />
                  }
                </div>
                <div className="flex justify-center w-full flex-wrap mt-4 pb-2">
                  <div></div>
                  <div className="custom-pagination order-first sm:order-last mb-3 sm:mb-0 mr-7">
                    {total > pageSize &&
                      <Pagination
                        activePage={activePage}
                        itemsCountPerPage={pageSize}
                        totalItemsCount={total}
                        pageRangeDisplayed={9}
                        onChange={this.handlePageChange.bind(this)}
                        itemClassPrev='previous'
                        itemClassNext='next'
                        itemClassFirst='first'
                        itemClassLast='last'
                        hideDisabled={true}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
        {showModal &&
          <UserForm
            showModal={showModal}
            setShowModal={this.closeModal}
            formType={formType}
            regionWithLocations={regionWithLocations}
            teachingRegionWithLocations={teachingRegionWithLocations}
            roles={userRoles}
            user={userForm}
            currentUser={user}
            business={business}
            time_zones={time_zones}
            skills={skills}
            addUser={this.addUser}
            editUser={this.editUser}
            showFormOther={showFormOther}
            showFormOtherTeaching={showFormOtherTeaching}
            currentUserPermissions={permissions}
            userSyncSkills={userSyncSkills || []}
            dataReady={dataReady}
            closeAndReload={this.closeAndReload}
            showAlert={this.showAlert}
            documentationCategories={documentationCategories}
          />
        }
      </Fragment>
    )
  }
}
