import React, { Fragment, useState, useEffect } from "react";
import CustomPopupContainerComponent from '../../../../../subRequestDetails/components/customPopupContainerComponent';
import InstructorFilter from './instructorFilter';
import StatusFilter from '../statusFilter';
import CategoryFilter from '../categoryFilter';
import LocationFilter from './locationFilter';
import { handleSaveDocumentationAllFilters, checkAllIdsSelected } from '../../../../helpers'
import SkillsFilter from './skillsFilter'

export default function FiltersPopup(props) {
  const { show, close, selectedInstructors, selectedStatus, statusFilterCounts, selectedCategories, categories, regionLocationsList, showRegions, selectedLocations, selectedRegions, handleClearFilter, handleUpdateAllFilters, user, selectedSkills, businessSkills } = props
  const [ selectedInstructorsFilter, setSelectedInstructorsFilter ] = useState(selectedInstructors || [])
  const [ selectedStatusFilter, setSelectedStatusFilter ] = useState(selectedStatus || [])
  const statusList  = [
    {value: 'submitted', label: 'Submitted'},
    {value: 'not_submitted', label: 'Not Submitted'},
    {value: 'expired', label: 'Expired'},
    {value: 'expiring', label: 'Expiring'}
  ]
  const allStatusValues = statusList.map((item) => item?.value)
  const [ selectedCategoriesFilter, setSelectedCategoriesFilter ] = useState(selectedCategories || [])
  const [ categoryIds, setCategoryIds ] = useState(categories.map((category) => category.id) || [])
  const [ selectedLocationsFilter, setSelectedLocationsFilter ] = useState(selectedLocations || [])
  const [ selectedRegionsFilter, setSelectedRegionsFilter ] = useState(selectedRegions || [])
  const [ selectedSkillsFilter, setSelectedSkillsFilter ] = useState(selectedSkills || [])
  
  useEffect(() => {
    setCategoryIds(categories.map((category) => category.id))
  }, [categories])

  const handleSelectValues = (regions, locations) => {
    setSelectedRegionsFilter(regions)
    setSelectedLocationsFilter(locations)
  }

  const processClear = () => {
    handleClearFilter()
    close()
  }

  const processApply = () => {
    let data = {
      statuses: selectedStatusFilter || [],
      instructors: selectedInstructorsFilter || [],
      categories: selectedCategoriesFilter || [],
      locations: selectedLocationsFilter || [],
      regions: selectedRegionsFilter || [],
      skills: selectedSkillsFilter || [],
    }
    handleUpdateAllFilters(data)
    let regions = regionLocationsList.map(function(a) {return a.region?.name;});
    data = {
      ...data,
      statusesState: ((selectedStatusFilter.length === allStatusValues.length) ? 'all_selected' : selectedStatusFilter?.length > 0 ? 'some_selected' : 'no_selected'),
      instructorsState: ((selectedInstructorsFilter?.length > 0) ? 'some_selected' : 'no_selected'),
      categoriesState: (checkAllIdsSelected(categoryIds, selectedCategoriesFilter) ? 'all_selected' : selectedCategoriesFilter?.length > 0 ? 'some_selected' : 'no_selected' ),
      locationsState: (regions.every(v => selectedRegionsFilter.includes(v)) && regionLocationsList?.length > 0) ? 'all_selected' : selectedLocationsFilter?.length > 0 ? 'some_selected' : 'no_selected',
      skillsState: ((selectedSkillsFilter?.length == businessSkills?.length) ? 'all_selected' : selectedSkillsFilter?.length > 0 ? 'some_selected' : 'no_selected')
    }    
    handleSaveDocumentationAllFilters(data, user?.id)
    close()
  }

  return (
    <Fragment>
      <CustomPopupContainerComponent show={show} close={close} title={'Filter Documentation'} customWidth={'sm:max-w-xl'}>
        <div className="flex flex-col justify-between px-8 py-4 min-h-520">
          <div className="flex flex-col gap-y-2 sm:gap-y-4">
            <div className="flex flex-col sm:flex-row items-start gap-2 sm:gap-4">
              <div className="flex flex-col w-full gap-1 sm:gap-2">
                <label htmlFor="name" className="block text-sm font-semibold leading-6 text-gray-900 flex justify-start">
                  Staff
                </label>
                <InstructorFilter
                  selectedInstructors={selectedInstructorsFilter}
                  handleSelectInstructors={setSelectedInstructorsFilter}
                />
              </div>
              <div className="flex flex-col w-full gap-1 sm:gap-2">
                <label htmlFor="name" className="block text-sm font-semibold leading-6 text-gray-900 flex justify-start">
                  Status
                </label>
                <StatusFilter
                  selectedStatus={selectedStatusFilter}
                  handleSelectedStatus={setSelectedStatusFilter}
                  statusFilterCounts={statusFilterCounts}
                  statusList={statusList}
                  allStatusValues={allStatusValues}
                />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row items-start gap-2 sm:gap-4">
              <div className="flex flex-col w-full gap-1 sm:gap-2">
                <label htmlFor="name" className="block text-sm font-semibold leading-6 text-gray-900 flex justify-start">
                  Category
                </label>
                <CategoryFilter
                  selectedCategories={selectedCategoriesFilter}
                  handleSelectedCategories={setSelectedCategoriesFilter}
                  categoriesList={categories}
                  categoryIds={categoryIds}
                />
              </div>
              <div className="flex flex-col w-full gap-1 sm:gap-2">
                <label htmlFor="name" className="block text-sm font-semibold leading-6 text-gray-900 flex justify-start">
                  Location
                </label>
                <LocationFilter
                  regionLocationsList={regionLocationsList || []}
                  selectedFilterLocations={selectedLocationsFilter}
                  hanldleSelectFilterLocations={handleSelectValues}
                  selectedFilterRegions={selectedRegionsFilter}
                  showRegions={showRegions} 
                />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row items-start gap-2 sm:gap-4">
              <div className="flex flex-col w-full gap-1 sm:gap-2">
                <label htmlFor="name" className="block text-sm font-semibold leading-6 text-gray-900 flex justify-start">
                  Skill
                </label>
                <SkillsFilter
                  selectedSkills={selectedSkillsFilter}
                  handleSelectSkills={setSelectedSkillsFilter}
                  businessSkills={businessSkills}
                />
              </div>
              <div className="flex flex-col w-full gap-1 sm:gap-2">
              </div>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row items-center gap-2 mt-4">
            <button
              type="button"
              className={`flex bg-white text-gray-900 px-6 items-center justify-center py-2 border shadow rounded-lg w-full text-sm h-11 hover:bg-gray-100`}
              onClick={(e) => processClear()}
            >
              Cancel All Filters
            </button>
            <button
              type="button"
              className={`flex bg-dark-blue text-white inline-block px-6 py-2 border border-gray-900 shadow rounded-lg w-full items-center justify-center text-sm h-11 hover:bg-gray-800`}
              onClick={(e) => processApply(e)}
            >
              Apply
            </button>
          </div>
        </div>
      </CustomPopupContainerComponent>
    </Fragment>
  )
}